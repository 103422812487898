'use strict';
(function ($) {
    var $formElement = $('#jwsdw-nlsignup-form'),
        xhr;

    $.validator.setDefaults({
        'groups': {
            'date': 'dateOfBirthDay dateOfBirthMonth dateOfBirthYear'
        },
        'rules': {
            'email': {
                'invalidvalue': true,
                'customemail': true
            },
            'dateOfBirth': {
                'checkDate': '#jwsdw-newsletter-dateOfBirth'
            }
        }
    });

    $formElement.validate();

    $formElement.submit(function(e) {
        var $form = $(this),
            $captcha = $form.find('.g-recaptcha'),
            $submitButton = $form.find('#jwsdw-newsletter-submit');

        e.preventDefault();

        // only submit form if frontend validation is successful and no other call is executed
        if (!$form.valid() || (xhr && xhr.readyState !== 4)) {
            return;
        }

        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            if (!window.grecaptcha || window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length !== 0) {
                return;
            }
            window.jwsdwUtil.captcha.execute($captcha.attr('id')).then(submitFormHandler);
        } else {
            submitFormHandler();
        }

        /**
         * @description Method to trigger submit again when form is valid
         * @returns {void}
         */
        function submitFormHandler() {
            var $err = $form.find('.jwsdw-newsletter-error');

            if (window.jwsdwRecaptchaSettings.captchaDisabled === false && window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length === 0) {
                // no response yet, check again in 200ms
                setTimeout(submitFormHandler, 200);
                return;
            }

            $submitButton.find('.jwsdw-buttonText').addClass('jwsdw-invisible');
            $submitButton.find('.jwsdw-spinner').removeClass('jws-hidden');
            $submitButton.attr('disabled', 'disabled');

            xhr = $.ajax(asURLEncoded($form))
                .done(function(response) {
                    $('.jwsdw-nlsignup-wrapper').html(response);
                    if (/jwsdw-nlsignup-success/.test(response)) {
                        window.jwsdwApi.tracking.fireEvent('newsletter', {
                            'type': 'newsletterSubscriptionSuccessful'
                        });
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'ce.misc',
                            'eventCategory': 'Misc',
                            'eventAction': 'Newsletter',
                            'eventLabel': 'signup',
                            'eventValue': undefined,
                            'nonInteraction': false
                        });
                    }
                }).fail(function(response) {
                    var message = window.app.resources.SERVER_CONNECTION_ERROR;
                    if (response.responseJSON) {
                        message = response.responseJSON.details.message;
                    }
                    $err.text(message).removeClass('jws-hidden');
                }).always(function() {
                    if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
                        window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
                    }
                    $submitButton.find('.jwsdw-buttonText').removeClass('jwsdw-invisible');
                    $submitButton.find('.jwsdw-spinner').addClass('jws-hidden');
                    $submitButton.removeAttr('disabled');
                });
        }

        /**
         * Method generate settings object for ajax requests
         * @param {String|object} form selector element to pass to jQuery
         * @returns {Object} ajax paremeters
         */
        function asURLEncoded(form) {
            form = $(form);
            return {
                'contentType': 'application/x-www-form-urlencoded; charset=UTF-8',
                'type': form.attr('method'),
                'url': form.attr('action'),
                'data': form.serialize()
            };
        }
    });
}(jQuery));


(function ($) {
    var $newsletterSignup = $('#jwsdw-newsletterSignup'),
        $newsletterSignupForm = $('#jwsdw-newsletterSignup-form'),
        $step1 = $newsletterSignup.find('.jwsdw-newsletterSignup-step1'),
        $step2 = $newsletterSignup.find('.jwsdw-newsletterSignup-step2'),
        $successMessage = $newsletterSignup.find('.jws-formSuccessMessage'),
        $generalErrorMessage = $newsletterSignup.find('.jwsdw-formErrorMessage'),
        $invalidFieldsErrorMessage = $newsletterSignup.find('.jwsdw-formErrorMessage-invalidFields'),
        $button = $newsletterSignup.find('.jws-buttonYellow'),
        $buttonText = $newsletterSignup.find('.jwsdw-buttonText'),
        $spinner = $newsletterSignup.find('.jwsdw-spinner'),
        xhr;

    $newsletterSignupForm.validate();

    window.jwsdwMediator.subscribe('closePicker', function (type) {
        if (type === 'newsletterSignupPicker') {
            reset();
        }
    });

    /**
     * @description Method to handle form submits
     * @param {Object} event the event that triggered the handler
     * @param {Function} successCallback the callback that is triggered when the ajax call was successful
     * @param {Function} errorCallback the callback that is triggered when the ajax call was unsuccessful
     * @param {Function} beforeSendCallback the callback that is triggered before the ajax form is sent
     * @param {Object} [fieldsToSend] optional fields that will be sent instead of the full form
     * @returns {void}
     */
    function formSubmit (event, successCallback, errorCallback, beforeSendCallback, fieldsToSend) {
        var $this = $(this),
            $captcha = $this.find('.g-recaptcha');

        event.preventDefault();

        // only submit form if frontend validation is successful and no other call is executed
        if (!$this.valid() || (xhr && xhr.readyState !== 4)) {
            return;
        }

        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            if (!window.grecaptcha || window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length !== 0) {
                return;
            }
            window.jwsdwUtil.captcha.execute($captcha.attr('id')).then(submitFormHandler);
        } else {
            submitFormHandler();
        }

        /**
         * @function
         * @description Handler to trigger submit again when form is valid
         * @return {void}
         */
        function submitFormHandler() {
            if (window.jwsdwRecaptchaSettings.captchaDisabled === false && window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length === 0) {
                // no response yet, check again in 200ms
                setTimeout(submitFormHandler, 200);
                return;
            }

            // XHR request to form action url with form fields as parameters
            xhr = $.ajax($this.attr('action'), {
                'data': !fieldsToSend ? $this.serialize() : fieldsToSend.serialize(),
                'beforeSend': beforeSendCallback,
                'error': errorCallback,
                'success': successCallback,
                'method': $this.attr('method') || 'GET'
            });
        }
    }

    /**
     * @description Method to invalidate given fields
     * @param {Object} $form form to invalidate
     * @param {Array} errorFields array of field names to invalidate
     * @returns {void} nothing
     */
    function invalidateFields($form, errorFields) {
        var validator = $form.validate(),
            errorFieldsObj = {};

        errorFields.forEach(function(field) {
            errorFieldsObj[field] = '';
        });
        validator.showErrors(errorFieldsObj);
        $invalidFieldsErrorMessage.removeClass('jws-hidden');
    }

    /**
     * @description Method to reset picker state
     * @returns {void}
     */
    function reset() {
        $step1.removeClass('jws-hidden');
        $step2.addClass('jws-hidden');
        $generalErrorMessage.addClass('jws-hidden');
        $invalidFieldsErrorMessage.addClass('jws-hidden');
        $successMessage.addClass('jws-hidden');
    }

    /**
     * @description Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function newsletterSignupBeforeSendCallback () {
        $button.prop('disabled', true);
        $buttonText.addClass('jwsdw-invisible');
        $spinner.removeClass('jws-hidden');
    }

    /**
     * @description Method to execute if address was handled successfully
     * @param {object} data the response data
     * @returns {object} response data
     */
    function newsletterSignupSuccessCallback (data) {
        if (!data.hasOwnProperty('status') || data.status !== 'success') {
            return newsletterSignupErrorCallback(data);
        }

        $button.prop('disabled', false);
        $buttonText.removeClass('jwsdw-invisible');
        $spinner.addClass('jws-hidden');

        $step1.addClass('jws-hidden');
        $step2.removeClass('jws-hidden');
        $successMessage.removeClass('jws-hidden');

        window.jwsdwMediator.publish('updateVerticalScroll');

        window.jwsdwApi.tracking.fireEvent('newsletter', {
            'type': 'newsletterSubscriptionSuccessful'
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'ce.misc',
            'eventCategory': 'Misc',
            'eventAction': 'Newsletter',
            'eventLabel': 'signup',
            'eventValue': undefined,
            'nonInteraction': false
        });
        return data;
    }

    /**
     * @description Method to execute if address was not handled successfully
     * @param {object} data the response data
     * @returns {void}
     */
    function newsletterSignupErrorCallback (data) {
        $button.prop('disabled', false);
        $buttonText.removeClass('jwsdw-invisible');
        $spinner.addClass('jws-hidden');

        $step1.addClass('jws-hidden');
        $step2.removeClass('jws-hidden');

        window.grecaptcha.reset();
        window.jwsdwMediator.publish('updateVerticalScroll');

        if (data.hasOwnProperty('error') && data.error.hasOwnProperty('errorFields')) {
            invalidateFields($newsletterSignup, data.error.errorFields);
        } else {
            $('.jwsdw-newsletterSignup-errorMessage').text(data.message);
            $generalErrorMessage.removeClass('jws-hidden');
        }
    }

    /**
     * @description Method to execute after the ajax call is made
     * @returns {void}
     */
    function newsletterSignupCompleteCallback () {
        var $captcha = $newsletterSignupForm.find('.g-recaptcha');

        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
        }
    }

    $newsletterSignupForm.submit(function (event) {
        formSubmit.call(
            this,
            event,
            newsletterSignupSuccessCallback,
            newsletterSignupErrorCallback,
            newsletterSignupBeforeSendCallback,
            newsletterSignupCompleteCallback
        );
    });

    $newsletterSignup.find('a[href*="newsletter"]').click(function(e) {
        e.preventDefault();
        reset();
    });
}(jQuery));
